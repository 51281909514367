* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

.container {
  max-width: 1364px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 23px;
  padding-right: 23px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: -11.5px;
}

.col {
  width: 100%;
  padding: 11.5px;

  &_3 {
    width: 25%;

    @include tablet {
      width: 100%;
    }
  }

  &_4 {
    width: 33.333333%;

    @include tablet_sm {
      width: 100%;
    }
    // @include desktop {
    //   width: 100%;
    // }
  }

  &_6 {
    width: 50%;

    @include tablet_sm {
      width: 100%;
    }
  }

  &_8 {
    width: 66.666667%;

    @include tablet {
      width: 100%;
    }
  }

  &_9 {
    width: 75%;

    @include tablet {
      width: 100%;
    }
  }
}

.desktop-hide {
  @include desktop {
    display: none !important;
  }
}

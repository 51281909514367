@import './mixins.pure.scss';

// Подключение шрифтов
@include font-face(OpenSans, "@this-assets/fonts/OpenSans-Regular", normal);
@include font-face(
  OpenSans-italic,
  "@this-assets/fonts/OpenSans-Italic",
  normal,
  italic
);
@include font-face(OpenSans, "@this-assets/fonts/OpenSans-SemiBold", 600);
@include font-face(OpenSans, "@this-assets/fonts/OpenSans-Bold", bold);

@include font-face(Montserrat, "@this-assets/fonts/Montserrat-SemiBold", 600);
@include font-face(Montserrat, "@this-assets/fonts/Montserrat-Bold", bold);
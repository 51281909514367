$ENV_CDN_URL: "undefined";
@import '@this-core/basic-styles/colors-const.pure.scss';
@import '../../../scss/utils/vars.pure.scss';
@import '../../../scss/utils/media_mixins.pure.scss';

.container {
  max-width: 650px;
  background-color: $white;
  border-radius: 8px;
  box-shadow: $shadow-2;
}

.img {
  width: 186px;
  height: 186px;
  border-top-left-radius: 8px;
  svg {
    border-top-left-radius: 8px;
  }
}

.rightBlock {
  width: 465px;
  min-height: 200px;
}


@include desktop_lg {
  .rightBlock {
    width: 400px;
  }
}

@include desktop {
  .rightBlock {
    width: 320px;
  }
}

@include tablet {
  .rightBlock {
    width: 465px;
  }
}
$ENV_CDN_URL: "undefined";
@import '@this-core/basic-styles/colors-const.pure';

.simpleBlueBtn {
  background: $dark-blue;
  border-radius: 48px;
  padding: 9px 36px 7px 36px;
}
.simpleBigBtn {
  border-radius: 48px;
  padding: 13px 0 11px 0;
}
.simpleLargeBtn {
  border-radius: 48px;
  padding: 21px 0 19px 0;
}

.simpleWhiteBtn {
  background: $white;
  border: 2px solid $dark-blue;
  border-radius: 48px;
  padding: 9px 36px 7px 36px;
  p {
    color: $dark-blue;
  }
}
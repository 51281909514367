$ENV_CDN_URL: "undefined";
@import '@this-core/basic-styles/colors-const.pure';
@import '../../../../src/scss/utils/vars.pure.scss';


.circleMarker {
  background-color: $dark-blue;
  padding: 12px;
  width: 35px;
  height: 35px;
  border-radius: 24px;
  box-shadow: $shadow-5;
}
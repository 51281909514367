$ENV_CDN_URL: "undefined";
@import '../../../scss/utils/media_mixins.pure.scss';
@import '../../../scss/utils/vars.pure';
@import '@this-core/basic-styles/colors-const.pure.scss';

.item {
  position: relative;
  padding: 5px;
  max-width: 170px;
}

.numbering {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 24px 0px;
  background-color: $light-blue;
}

.iconStyle {
  position: relative;
  background: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 31px;
  border-radius: 24px;
  box-shadow: $shadow-2;
}

.text {
  margin-top: 16px;
  margin-bottom: 15px;
  font-size: 21px;
  line-height: 28px;
  font-weight: 600;
  font-family: "OpenSans", sans-serif;
  text-align: center;
  color: $gray;
}

@include mobile {
  .item {
    padding: 5px;
    max-width: 150px;
  }
}
$ENV_CDN_URL: "undefined";
@import '../../../front-land-invest-core/basic-styles/colors-const.pure';
@import '../../scss/utils/vars.pure';
@import '../../scss/utils/media_mixins.pure';

.backgroundImg {
  position: absolute;
  top: -1400px;
  left: 0;
  width: 100%;
  z-index: -1;
  img {
    width: 100%;
    height: fit-content;
    object-position: top center;
  }
}

.titleContainer {
  display: grid;
  grid-template-columns: 192px 1fr;
  grid-column-gap: 24px;
}
.logoBlock {
  width: 192px;
  height: 192px;
  background-color: $light-blue;
  border-radius: 32px;
}

.iconsBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @include tablet {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
  }
}
.boxWhithShadow {
  position: relative;
  background: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 31px;
  border-radius: 24px;
  box-shadow: $shadow-2;
}
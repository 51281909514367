.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $color-FFFFFF;
  padding-top: 9px;
  padding-bottom: 9px;
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
  transition: 0.4s $ease-in-out-sine;

  &__content {
    border-radius: 24px;
    background: $color-FFFFFF;
    box-shadow: $shadow-3;
    padding: 24px 8px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(30%);
    transition: 0.4s $ease-in-out-sine;
  }

  &.active {
    opacity: 1;
    visibility: visible;

    .popup {
      &__content {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &_security {
    .container {
      max-width: 748px;
    }

    .popup {
      &__content {
        padding: 24px 24px 48px 16px;
      }

      &__title-block {
        display: flex;
        align-items: center;
        padding: 0;
        > .title {
          max-width: 370px;
          text-align: left;
        }
      }
    }

    .btn-block {
      > .row {
        > .col {
          align-items: flex-end;
        }
      }
    }
  }

  &__text {
    p {
      margin-bottom: 20px;
      margin-top: -10px;
      font-size: 14px;
      line-height: 150%;
      color: $color-7E96AE;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        color: $color-7E96AE;
        text-decoration: underline;
      }
    }
  }

  &__title-block {
    position: relative;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

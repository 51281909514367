@mixin bottom-dot-pro() {
  background-image: linear-gradient(to right, white 33%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 0.15rem 0.08rem;
  background-repeat: repeat-x;
}

@mixin bottom-dot-pro() {
  background-image: linear-gradient(to right, white 33%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 0.15rem 0.08rem;
  background-repeat: repeat-x;
}

@mixin bottom-dot-bold-pro() {
  background-image: linear-gradient(to right, white 33%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 0.2rem 0.1rem;
  background-repeat: repeat-x;
}

@mixin bottom-dot-bold-pro-black() {
  background-image: linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 0.2rem 0.1rem;
  background-repeat: repeat-x;
}

@mixin bottom-dot() {
  padding-bottom: (0.05rem*2);
  border-bottom: 0.08rem;
  border-bottom-style: dotted;
}
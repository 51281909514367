.leaflet-container {
  height: 85vh;
  width: 100%;
}

.leaflet-popup-content {
  width: 366px !important;
  margin: 13px 19px;
	line-height: 1;
}
.leaflet-popup-content p {
	margin: 0 0 !important;
}

.leaflet-pane {
  z-index: 0 !important;
}

.leaflet-popup-close-button {
  color: #3F4E5D !important;
  font: 30px/30px Tahoma,Verdana,sans-serif !important;
  padding: 0 !important;
  margin: 10px 15px 0 0;
}
$ENV_CDN_URL: "undefined";
@import '../../../scss/utils/vars.pure';
@import '../../../../front-land-invest-core/basic-styles/colors-const.pure';
@import '../../../scss/utils/media_mixins.pure.scss';

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 34px;
  @include tablet {
    grid-template-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 30px;
  }
}

.item {
  background: $white;
  border-radius: 24px;
  box-shadow: $shadow-2;
}
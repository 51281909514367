@import '../utils/mixins.pure.scss';

button {
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 0;
  appearance: none;
}

input,
textarea {
  outline: none;
  border: 0;
  font-family: "OpenSans";
  font-weight: normal;
  font-size: 14px;
  transition: 0.4s $ease-in-out-sine;

  &::placeholder {
    transition: 0.4s $ease-in-out-sine;
  }
}

.form {
  input {
    &[type="text"],
    &[type="number"],
    &[type="email"],
    &[type="date"] {
      outline: none;
      font-family: "OpenSans", sans-serif;
      font-size: 16px;
      font-weight: normal;
      color: $color-3F4E5D;
      display: flex;
      align-items: center;
      padding: 8px 12px;
      width: 100%;
      border-radius: 8px;
      background: $color-FFFFFF;
      border: 1px solid $color-7E96AE;
      appearance: none;

      &::placeholder {
        font-family: "OpenSans", sans-serif;
        color: $color-7E96AE;
      }

      &:focus {
        border: 1px solid $color-3867B1;
      }
    }

    &[type="date"] {
      position: relative;
      // padding-bottom: 4px;
      border: none;
      padding-right: 44px;

      &:focus {
        border: none;

        &::before {
          border: 1px solid #3867b1;
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 44px;
        border: 1px solid $color-7E96AE;
        border-radius: 8px;
        transition: 0.4s $ease-in-out-sine;
      }

      &::-webkit-inner-spin-button {
        display: none;
      }

      &::-webkit-calendar-picker-indicator {
        position: absolute;
        right: 0;
        width: 24px;
        height: 24px;
        // background: url('@this-assets/icons/ui/calendar-icon.svg');   Need refactoring
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        cursor: pointer;
        z-index: 2;
      }
    }
  }

  textarea {
    height: 102px;
    resize: none;
    font-size: 16px;
    font-weight: normal;
    color: $color-3F4E5D;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    width: 100%;
    border-radius: 8px;
    background: $color-FFFFFF;
    border: 1px solid $color-7E96AE;
    appearance: none;

    &::placeholder {
      font-family: "OpenSans", sans-serif;
      color: $color-7E96AE;
    }
  }

  label {
    width: 100%;
    display: block;
    position: relative;

    span {
      display: block;
      font-weight: normal;
      line-height: 131%;
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    em {
      font-style: normal;
      color: $color-328C45;
      font-weight: 600;
    }
  }
}

.require {
  position: relative;

  input {
    &[type="text"],
    &[type="number"],
    &[type="email"],
    &[type="date"] {
      border: 1px solid $color-FA034D;
      color: $color-FA034D;

      &::placeholder {
        color: $color-FA034D;
      }

      &:focus {
        border: 1px solid $color-FA034D;
      }
    }
  }

  .select {
    &__is-active {
      &.hidden {
        border: 1px solid $color-FA034D;
        color: $color-FA034D;

        &::after {
          @include triangle(bottom, 7px, 7px, $color-FA034D);
        }
      }
    }
  }

  &__error {
    position: absolute;
    top: 100%;
    left: 12px;
    margin-top: 8px;
    font-size: 12px;
    color: $color-FA034D;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s $ease-in-out-sine;

    &.error {
      opacity: 1;
      visibility: visible;
    }
  }
}

.select {
  position: relative;
  font-family: "OpenSans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  z-index: 2;

  @include mobile {
    font-size: 14px;
  }

  @include tablet {
    font-size: 15px;
  }

  &.active {
    .select {
      &__is-active {
        &::after {
          transform: rotate(180deg);
        }
      }

      &__dropdown {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__is-active {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 43px 8px 12px;
    width: 100%;
    border-radius: 8px;
    background: $color-FFFFFF;
    border: 1px solid $color-7E96AE;
    cursor: pointer;
    z-index: 101000;

    &::after {
      position: absolute;
      right: 17px;
      top: 0;
      bottom: 0;
      margin: auto;
      @include triangle(bottom, 7px, 7px, $color-3F4E5D);
      transition: 0.4s $ease-in-out-sine;
    }

    &.hidden {
      color: $color-7E96AE;
    }
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    max-width: 350px;
    margin-top: -8px;
    padding: 15px;
    background: $color-FFFFFF;
    border-radius: 0 0 8px 8px;
    border: 1px solid $color-7E96AE;
    border-top: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s $ease-in-out-sine;
    z-index: 1;
    max-height: 300px;
    overflow-y: auto;
    > div {
      margin-bottom: 10px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.autocomplite-input {
  position: relative;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s $ease-in-out-sine;

  @include desktop {
    max-height: 0;
  }

  &.active {
    opacity: 1;
    visibility: visible;

    @include desktop {
      max-height: 100vh;
    }
  }

  > input {
    position: relative;
    z-index: 2;
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: -8px;
    padding: 23px 43px 15px 12px;
    background: $color-FFFFFF;
    border-radius: 0 0 8px 8px;
    border: 1px solid $color-7E96AE;
    border-top: 0;
    // opacity: 0;
    // visibility: hidden;
    transition: 0.4s $ease-in-out-sine;
    z-index: 1;

    > ul {
      > li {
        margin-bottom: 10px;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.main-form {
  position: relative;
  border-radius: 24px;
  background: $color-FFFFFF;
  box-shadow: $shadow-2;
  padding: 24px 24px 31px 24px;
  // transition: 0.4s $ease-in-out-sine;

  &_absolute {
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    max-width: 1046px;
    margin-left: auto;
    margin-right: auto;
  }

  &__stage-1,
  &__stage-2,
  &__stage-3,
  &__stage-4,
  &__stage-5,
  &__rejection-reason,
  &__success {
    display: none;

    &.active {
      display: block;
    }
  }

  &__stage-1 {
    > .row {
      > .col {
        display: flex;
        align-items: flex-end;

        @include desktop {
          width: 100%;
        }
      }
    }

    .btn {
      max-width: 100%;
      margin-top: auto;

      @include desktop {
        max-width: 408px;
      }
    }

    .checkbox,
    .btn {
      @include desktop {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &_sale {
      > .row {
        > .col {
          @include desktop {
            // width: 100%;

            &:nth-child(6) {
              order: 7;
            }

            &:nth-child(7) {
              order: 6;
            }

            &:nth-child(8) {
              order: 9;
            }

            &:nth-child(9) {
              order: 8;
            }
          }
        }
      }
    }

    &_buy {
      > .row {
        > .col {
          @include desktop {
            &:nth-child(11) {
              order: 10;
            }

            &:nth-child(9) {
              order: 11;
            }
          }
        }
      }
    }
  }

  &__stage-2 {
    .main-form {
      &__content {
        .title {
          margin-bottom: 12px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .btn-block {
      margin-top: 46px;
    }
  }

  &__stage-4,
  &__stage-5 {
    .img-upload {
      .btn-block {
        > .row {
          > .col {
            align-items: flex-end;

            @include tablet {
              align-items: center;
            }
          }
        }

        .btn {
          margin-bottom: 12px;
          max-width: 356px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .checkbox {
          margin-right: 16px;
        }
      }
    }
  }

  &__stage-4 {
    > .btn-block {
      padding-bottom: 32px;
      border-bottom: 1px solid $color-7E96AE;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__stage-5 {
    .main-form {
      &__content {
        margin-bottom: 49px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .img-upload {
      &__triger-wrapper {
        margin-top: 32px;
        &:first-child {
          margin-top: 0;
        }
      }
      &__info-item-img {
        &::before {
          display: none;
        }
      }
    }
  }

  &__success {
    // background: $color-303A4B;
    .btn {
      max-width: 408px;
    }
  }

  &__rejection-reason {
    .main-form {
      &__img {
        img {
          max-width: 256px;
        }
      }

      &__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .btn-block {
      .btn,
      .checkbox {
        max-width: 453px;
      }

      .checkbox {
        margin-top: 12px;
        width: 100%;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .btn-block {
      > .row {
        > .col {
          @include tablet {
            align-items: center;
          }
        }
      }
    }
  }

  &__title-block {
    position: relative;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .title_ms{
    font-size: 12px;
    padding-top: 15px;
  }

  .title {
    &_md-md {
      text-align: center;
      color: $color-7E96AE;
      letter-spacing: 0.01em;
      font-weight: 600;
      text-transform: initial;
    }
  }

  &__total-price {
    margin: 0 auto 25px auto;
    padding-bottom: 3px;
    border-bottom: 1px solid $color-7E96AE;
    max-width: 874px;

    &:last-child {
      margin-bottom: 0;
    }

    .title {
      strong {
        color: $color-3867B1;
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 64px;
        text-align: center;
        line-height: 100%;

        @include mobile {
          font-size: 44px;
          margin-bottom: 40px;
        }

        @include tablet {
          font-size: 56px;
          margin-bottom: 50px;
        }

        @include desktop {
          font-size: 60px;
        }
      }
    }

    &_2 {
      margin: 0 auto;
      padding-bottom: 0;
      border-bottom: 0;

      .title {
        strong {
          color: $color-3867B1;
          font-family: "Montserrat";
          font-weight: bold;
          font-size: 34px;
          text-align: center;
          line-height: 100%;

          @include mobile {
            font-size: 22px;
            margin-bottom: 40px;
          }

          @include tablet {
            font-size: 28px;
            margin-bottom: 50px;
          }

          @include desktop {
            font-size: 32px;
          }
        }
      }
    }
  }

  &__img {
    img {
      display: block;
      width: 100%;
    }
  }
}

.steps {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin-top: 56px;
  border-radius: 24px;
  background: $color-FFFFFF;
  box-shadow: $shadow-2;
  padding: 31px 31px 33px 31px;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s $ease-in-out-sine;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &__items {
    max-width: 736px;
    margin-left: auto;
    margin-right: auto;

    > .row {
      justify-content: space-between;
      counter-reset: counter;

      > .col {
        width: initial;
        position: relative;

        &:nth-child(2) {
          &::before {
            background: $gradient-3;
          }
        }

        &:last-child {
          &::before {
            display: none;
          }
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          margin-top: auto;
          margin-bottom: auto;
          left: 50%;
          height: 12px;
          width: 260%;
          background: $gradient-2;
        }
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 88px;
    height: 88px;
    border-radius: 50%;
    background: $color-FFFFFF;
    box-shadow: $shadow-1;
    z-index: 2;

    &::after {
      content: "";
      position: absolute;
      top: 6px;
      left: 6px;
      right: 6px;
      bottom: 6px;
      border-radius: 50%;
      background: $color-529ED0;
      z-index: 1;
      transition: 0.4s $ease-in-out-sine;
    }

    &::before {
      position: relative;
      counter-increment: counter;
      content: counter(counter);
      color: $color-FFFFFF;
      font-weight: 600;
      font-size: 24px;
      margin-right: 4px;
      z-index: 2;
      transition: 0.4s $ease-in-out-sine;

      &:last-child {
        margin-right: 0;
      }
    }

    &_white {
      &::after {
        background: $color-FFFFFF;
        border: 2px solid $color-7E96AE;
      }

      &::before {
        color: $color-7E96AE;
      }

      .steps {
        &__icon {
          background: $color-7E96AE;
        }
      }
    }
  }
}

.submit-preloader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: fade-out($color-3F4E5D, 0.25);
  opacity: 0;
  visibility: hidden;
  z-index: 99999;
  transition: 0.4s $ease-in-out-sine;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
      display: block;
      margin-bottom: 35px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    p {
      color: $color-FFFFFF;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 89%;
    }
  }
}

.rejection-reason-radio {
  label {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    // width: fit-content;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    > input {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    textarea {
      margin-top: 16px;
      transition: 0.4s $ease-in-out-sine;

      &:focus {
        border: 1px solid #3867b1;
      }

      // &:first-child {
      //   margin-top: 0;
      // }
    }

    span {
      font-weight: 600;
      font-size: 21px;
      margin-bottom: 0;
    }
  }
}

.tooltip {
  position: absolute;
  top: -70%;
  // bottom: 0;
  left: 26%;
  margin: auto;
  display: flex;
  align-items: center;
  padding: 12px 30px;
  background: $color-FFFFFF;
  border-radius: 16px;
  box-shadow: $shadow-5;
  opacity: 0;
  visibility: hidden;
  transform: translateX(10px);
  transition: 0.4s $ease-in-out-sine;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }

  &_relative {
    width: 100%;
    position: relative;
    top: initial;
    left: initial;
    transform: translateX(0);
    box-shadow: none;
    padding: 0;
    border-radius: 0;
    max-height: 0;
    height: auto;
    overflow: hidden;
    transition: 0.4s $ease-in-out-sine;

    &.active {
      max-height: 100vh;
      // transition: max-height 0.4s $ease-in-out-sine;
    }

    &::before,
    &::after {
      display: none;
    }
  }

  // &::before,
  // &::after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   margin-top: auto;
  //   margin-bottom: auto;
  // }

  // &::before {
  //   width: 31px;
  //   height: 39px;
  //   right: 100%;
  //   margin-right: -12px;
  //   background: url('@this-assets/icons/ui/rejection-reason-radio-tooltip-icon.svg');
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   background-size: 100% 100%;
  // }

  // &::after {
  //   background: $color-FFFFFF;
  //   width: 10px;
  //   height: 39px;
  //   left: 0;
  // }

  span {
    color: $color-3867B1;
    margin-right: 26px;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }
  }

  input {
    &[type="text"],
    &[type="number"],
    &[type="email"],
    &[type="date"] {
      min-width: 260px;
      font-size: 14px;
    }
  }
}

.img-upload {
  > .row {
    margin: -43px;

    > .col {
      padding: 43px;

      @include tablet {
        width: 100%;
      }
    }
  }
  &__items {
    margin-top: 42px;
    &:first-child {
      margin-top: 0;
    }
    > .row {
      margin: -8px;
      > .col {
        padding: 8px;
      }
    }
  }

  &__item {
    position: relative;
    height: 100%;

    img {
      display: block;
      width: 100%;
      border-radius: 8px;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &_file {
      padding-right: 40px;
      width: fit-content;
    }
  }

  &__del {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    background: url('@this-assets/imgs/delete-icon.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    cursor: pointer;
  }

  &__info-items {
    > .row {
      margin: -37.5px;

      @include mobile {
        margin: -10px;
      }

      @include tablet_sm {
        margin: -20px;
      }

      > .col {
        padding: 37.5px;
        width: 50%;

        @include mobile {
          padding: 10px;
        }

        @include tablet_sm {
          padding: 20px;
        }

        &:last-child {
          .img-upload {
            &__info-item-img {
              &::after {
                display: none !important;
              }
            }
          }
        }
      }
    }

    &_small {
      > .row {
        margin: -8px;

        > .col {
          padding: 8px;

          .img-upload {
            &__info-item-img {
              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &__info-item-img {
    position: relative;
    margin-bottom: 12px;
    position: relative;

    &::before {
      counter-increment: count;
      content: counter(count);
      position: absolute;
      bottom: 0;
      left: 0;
      background: $color-328C45;
      border-radius: 50%;
      color: $color-FFFFFF;
      font-weight: bold;
      font-size: 10px;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:last-child {
      margin-bottom: 0;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__info-item-content {
    font-size: 12px;
    line-height: 100%;
    color: $color-7E96AE;
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.grecaptcha-badge {
  display: none;
}

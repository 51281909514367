@import './colors-const.pure.scss';
@import './mixins.pure.scss';

.montserrat {
  font-family: "Montserrat", sans-serif !important;
}

.black {
  color: $black;
}
.white {
  color: $white;
}
.orange {
  color: $orange;
}
.blue {
  color: $blue;
}
.light-gray {
  color: $light-gray;
}
.gray {
  color: $gray;
}
.dark-gray {
  color: $dark-gray;
}
.dark-blue {
  color: $dark-blue;
}

// Usefull
.none {
  display: none;
}
.pointer {
  cursor: pointer;
}
.to-lowercase {
  text-transform: lowercase
}
.to-capitalize {
  text-transform: capitalize
}
.to-uppercase {
  text-transform: uppercase;
}
.t-center {
  text-align: center;
}
.height-50 {
  height: 50px;
}
.z-index-3 {
  z-index: 3;
}

// Like bootstrap style
.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-around {
  justify-content: space-around;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}
.flex-wrap {
  flex-wrap: wrap!important;
}

.bottom-dot-pro {
  @include bottom-dot-pro();
}
.bottom-dot-bold-pro {
  @include bottom-dot-bold-pro();
}
.bottom-dot-bold-pro-black {
  @include bottom-dot-bold-pro-black()
}
.bottom-dot-pro {
  @include bottom-dot-pro();
}
.bottom-dot {
  @include bottom-dot();
}

@mixin f-size($size, $height, $bold: false) {
  font-size: (1px*$size);
  line-height: (1px*$height);
  @if $bold {font-weight: 700;}
}

$fontStyle:
  12 16,
  14 16,
  14 18,
  14 19,
  16 20,
  18 24,
  21 24,
  21 28,
  24 24,
  24 28,
  24 29,
  24 32,
  26 28,
  32 36,
  32 40,
  40 48,
  40 49,
  40 56,
  32 42,
  32 64,
  40 50;

@each $f-size, $l-height in $fontStyle {
  .fs-#{$f-size}-#{$l-height}-b,
  .fs-#{$f-size}-#{$l-height} {
    @include f-size($f-size, $l-height);
  }
  .fs-#{$f-size}-#{$l-height}-b {
    font-weight: 700;
  }
}
.fw-600 {
  font-weight: 600;
}

@mixin overflow-hide($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
}

$textOverflow: 3, 5, 7, 10;
@each $line in $textOverflow {
  .text-overflow-hide-#{$line} {
    @include overflow-hide($line);
  }
}

$margin-padding:
  2,
  3,
  4,
  5,
  8,
  10,
  12,
  15,
  16,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  55,
  70,
  75,
  80,
  90,
  100,
  110,
  130,
  190;

@each $indents in $margin-padding {
  .m-#{$indents} {
    margin: (1px*$indents);
  }
  .mx-#{$indents} {
    margin-left: (1px*$indents);
    margin-right: (1px*$indents);
  }
  .my-#{$indents} {
    margin-top: (1px*$indents);
    margin-bottom: (1px*$indents);
  }
  .mt-#{$indents} {
    margin-top: (1px*$indents);
  }
  .mb-#{$indents} {
    margin-bottom: (1px*$indents);
  }
  .ml-#{$indents} {
    margin-left: (1px*$indents);
  }
  .mr-#{$indents} {
    margin-right: (1px*$indents);
  }

  .p-#{$indents} {
    padding: (1px*$indents);
  }
  .px-#{$indents} {
    padding-left: (1px*$indents);
    padding-right: (1px*$indents);
  }
  .py-#{$indents} {
    padding-top: (1px*$indents);
    padding-bottom: (1px*$indents);
  }
  .pt-#{$indents} {
    padding-top: (1px*$indents);
  }
  .pb-#{$indents} {
    padding-bottom: (1px*$indents);
  }
  .pl-#{$indents} {
    padding-left: (1px*$indents);
  }
  .pr-#{$indents} {
    padding-right: (1px*$indents);
  }
}
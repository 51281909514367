.btn {
  border-width: 1px;
  border-style: solid;
  outline: none;
  border-radius: 48px;
  font-family: "OpenSans";
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 344px;
  height: 48px;
  margin-left: auto;
  margin-right: auto;
  padding: 8px 12px;
  cursor: pointer;
  transition: 0.4s $ease-in-out-sine;

  @include mobile {
    font-size: 14px;
  }

  @include tablet {
    font-size: 16px;
  }

  &_dark-blue {
    background: $color-3867B1;
    border-color: $color-3867B1;
    color: $color-FFFFFF;

    .btn {
      &__icon {
        background: $color-FFFFFF;
      }
    }

    &_disable {
      background: fade-out($color-7E96AE, 0.3);
      border-color: fade-out($color-7E96AE, 0.3);
    }
  }

  &_white {
    background: $color-FFFFFF;
    border-color: $color-3867B1;
    color: $color-3867B1;
  }

  &_security {
    border-color: transparent;
    justify-content: flex-start;
    padding: 0;
    height: initial;
    margin: initial;
    max-width: initial !important;
    width: initial;

    span {
      font-weight: 600;
      font-size: 21px;
      color: $color-3867B1;
      text-align: left;

      @include mobile {
        font-size: 16px;
      }

      @include tablet {
        font-size: 18px;
      }

      @include desktop {
        font-size: 19px;
      }
    }
  }
}

.btn-block {
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }

  > .row {
    margin-left: -51px;
    margin-right: -51px;

    @include tablet {
      margin-left: -11.5px;
      margin-right: -11.5px;
    }

    > .col {
      padding-left: 51px;
      padding-right: 51px;
      display: flex;
      flex-direction: column;
      // display: flex;
      // justify-content: flex-end;

      // &:nth-child(2n) {
      //   justify-content: flex-start;
      // }

      @include tablet {
        padding-left: 11.5px;
        padding-right: 11.5px;
      }
    }
  }

  .btn {
    margin: initial;
  }

  &_center {
    > .row {
      > .col {
        align-items: flex-end;
        // justify-content: flex-end;

        @include tablet_sm {
          align-items: center !important;
        }

        &:nth-child(2n) {
          // justify-content: flex-start;
          align-items: flex-start;
        }
      }
    }
  }

  &_right {
    // display: flex;
    // justify-content: flex-end;

    > .row {
      justify-content: flex-end;

      > .col {
        justify-content: flex-end;
      }
    }
  }
}

.investmentContentDefault {
  position: relative;
  /*margin-top: 24px;*/
  margin: 25px;
  padding-bottom: 25px;
}
.investmentTextColor{
  color: rgb(216, 111, 13);
}

@import "https://netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css";
a:link{text-decoration:none}

.vn-teal a{
background-color:#3867b1;
  display:inline-block;
  position:relative;
  margin:15px 5px;
  padding:20px 20px 20px 80px;
  color:rgb(255, 255, 255);
  transition:all 0.4s ease
}

.vn-teal a:before{
  content:"\f019";
  font-family:fontAwesome;
  position:absolute;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  font-size:28px;
  border-radius:0 20px 0 0;
  color:#204888;
  background-color:#fff;
  opacity:0.3;
  padding:20px;
  top:0;
  left:0
}

.vn-teal a:hover{
  background:#105091
}
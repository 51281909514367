.footer {
  position: relative;
  background: $gradient-1;
  color: $color-3867B1;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 25px;
  z-index: 2;

  > .container {
    max-width: 1276px;
    > .row {
      margin-top: -25px;
      margin-bottom: -25px;
      > .col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 29.6485%;
        margin: 35px 0;
        padding: 0px 25px;
        @include tablet {
          width: 100% !important;
        }
        &:nth-child(1) {
          @include tablet {
            order: 1;
          }
        }
        &:nth-child(2) {
          width: 40.703%;

          @include tablet {
            order: 3;
          }
        }
        &:nth-child(3) {
          @include tablet {
            order: 2;
          }
        }
      }
    }
  }

  &__left {
    height: 100%;
    .main-nav {
      height: 100%;
      ul {
        height: 100%;
        display: block;
        align-items: initial;
        margin-bottom: -22px;
        columns: 2;
        @include tablet {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        li {
          height: initial;
          padding: 0;
          margin-bottom: 16px;
          margin-right: 0;
          border: none;
        }
      }
    }
  }

  &__center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
    padding-left: 23px;
    padding-right: 23px;
    border-left: 1px solid $color-FFFFFF;
    border-right: 1px solid $color-FFFFFF;
    @include tablet {
      border-left: 0;
      border-right: 0;
    }
    p {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  a {
    color: $color-FFFFFF;
  }
}

.social {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@import '../utils/vars.pure.scss';

.slick-slider {
  display: none;
  position: relative;

  &.slick-initialized {
    display: block;
  }

  .slick-prev,
  .slick-next,
  .slick-slide a {
    outline: none;
    cursor: pointer;
  }

  .slick-slide {
    outline: none;

    > div {
      > div,
      > a {
        outline: none;
      }
    }

    img {
      display: block;
      width: 100%;
    }
  }

  .slick-list {
    overflow: hidden;
  }

  .slick-track {
    display: flex;
  }

  .slick-dots {
    display: flex !important;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    margin-top: 32px;

    // @include tablet {
    //   padding-bottom: 30px;
    // }

    li {
      display: block;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }

      button {
        height: 16px;
        width: 16px;
        cursor: pointer;
        border-radius: 50%;
        background: $color-CEDDE6;
        border: none;
        outline: none;
        color: transparent;
        font-size: 0;
        // transition: 0.4s $ease-in-out-sine;
      }

      &.slick-active {
        button {
          background: $color-3867B1;
        }
      }
    }
  }
}

.now-on-sale {
  margin-top: 78px;

  .slick-list {
    overflow: visible;
    margin: -8px;
  }

  .slick-slide {
    padding: 8px;

    > div {
      height: 100%;
    }
  }

  .slick-dots {
    margin-right: 13.5%;
  }

  &__slider {
    margin-right: -13.5%;
  }

  &__item {
    height: 100%;
    border-radius: 8px;
    background: $color-FFFFFF;
    // box-shadow: $shadow-1;
    font-weight: 600;
    font-size: 14px;
    line-height: 129%;
  }

  &__item-top {
    height: calc(100% - 40px);
    display: flex;
    border-bottom: 1px solid $color-CEDDE6;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__img {
    width: 38%;
    height: 100%;
    border-radius: 8px 0px 0px 0px;
    overflow: hidden;

    img {
      display: block;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  // &__content {
  //   width: 62%;
  //   padding: 19px 16px 9px 10px;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;
  //   color: $color-7E96AE;
  // }

  &__head {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    p {
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }

      &:nth-child(1) {
        color: $color-3F4E5D;
        text-transform: inherit;

        a {
          color: $color-3F4E5D;
        }
      }
    }
  }

  &__price {
    p {
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }

      &:first-child {
        color: $color-3867B1;
        line-height: 114%;
        font-weight: bold;
        font-size: 21px;
      }
    }
  }

  &__item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 10px 16px;
    color: $color-3867B1;
    background: $color-ECF4F8;
    border-radius: 0px 0px 4px 4px;
  }
}

.people-about-us {
  margin-top: 99px;

  .slick-list {
    margin-left: -11.5px;
    margin-right: -11.5px;
  }

  &__item {
    max-width: 359px;
    margin-left: auto;
    margin-right: auto;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    padding-left: 11.5px;
    padding-right: 11.5px;
  }

  &__img {
    border-radius: 50%;
    overflow: hidden;
    max-width: 128px;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__content {
    text-align: center;
    // font-size: 12px;
  }

  &__people {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    p {
      color: $color-7E96AE;

      &:first-child {
        font-weight: bold;
        color: $color-3F4E5D;
      }
    }
  }

  &__comment {
    font-family: "OpenSans-italic";
    font-style: italic;
    font-size: 21px;
    line-height: 124%;

    @include mobile {
      font-size: 16px;
    }

    @include tablet {
      font-size: 18px;
    }

    @include desktop {
      font-size: 19px;
    }
  }
}

.news-slider {
  margin-top: 80px;
  margin-bottom: 68px;

  .slick-list {
    margin: -14px;
  }

  .slick-slide {
    padding: 14px;
  }

  &__item {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    color: $color-3F4E5D;
  }

  &__img {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      border-radius: 12px;
    }
  }

  &__title {
    text-align: center;
  }
}

.why-invest{
  text-align: center;
  font-size: 25px;
}
.why-invest-btn{
  padding-top: 25px;
}
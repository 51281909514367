$ENV_CDN_URL: "undefined";
@import '@this-core/basic-styles/colors-const.pure.scss';

.itemsBlock {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.item {
  max-width: 256px;
}

.title {
  font-size: 21px;
  line-height: 24px;
  font-weight: 700;
  color: $dark-blue;
}
@import './utils/fonts.pure.scss';
@import './utils/media_mixins.pure.scss';
@import './utils/vars.pure.scss';

// Modules
@import './modules/helpers.pure.scss';
@import './modules/header.pure.scss';
@import './modules/titles.pure.scss';
@import './modules/forms.pure.scss';
@import './modules/sliders.pure.scss';
@import './modules/buttons.pure.scss';
@import './modules/popups.pure.scss';

html {
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  font-family: "OpenSans";
  font-weight: normal;
  font-size: 18px;
  line-height: 133%;
  color: $color-3F4E5D;
  padding-top: 72px;
  overflow: hidden;

  @include mobile {
    font-size: 14px;
  }

  @include tablet {
    font-size: 16px;
  }
}

.home {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    top: 964px;
    height: 100%;
    background: url('@this-assets/imgs/background.png');
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 100% auto;
  }

  > .main-top-block {
    position: relative;
    z-index: 3;
  }

  > .container {
    position: relative;
    z-index: 2;
  }
}

.main-top-block {
  position: relative;
  height: 608px;
  margin-top: -72px;

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 84px;
    padding-bottom: 22px;
    z-index: 2;

    > .title {
      font-weight: 600;
      text-transform: initial;
    }
  }

  &__bg {
    position: relative;
    width: 100%;
    height: 100%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-position: top center;
    }
  }
}

.tabs {
  &__nav {
    display: flex;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    li {
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      text-transform: uppercase;
      overflow: hidden;

      &:first-child {
        border-radius: 24px 0px 0px 24px;
      }

      &:last-child {
        border-radius: 0px 24px 24px 0px;
      }

      a {
        display: block;
        padding: 12px 32px;
        color: $color-3F4E5D;
        background: $color-FFFFFF;
        transition: 0.3s $ease-in-out-sine;

        &.active {
          background: $color-3867B1;
          color: $color-FFFFFF;
        }
      }
    }
  }
}

.about-us {
  margin-top: 80px;

  &__content {
    text-align: justify;

    p {
      margin-bottom: 28px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__items {
    margin-top: 70px;
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;

    &:first-child {
      margin-top: 0;
    }

    > .row {
      // margin: -28.5px -1.5px;
      margin-left: -77px;
      margin-right: -77px;

      > .col {
        // padding: 28.5px;
        padding-left: 77px;
        padding-right: 77px;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__item-icon {
    border-radius: 24px;
    background: $color-FFFFFF;
    padding: 35px 28px;
    box-shadow: $shadow-2;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__item-content {
    > .title {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    p {
      font-weight: 600;
      font-size: 21px;
      color: $color-7E96AE;

      @include mobile {
        font-size: 17px;
      }

      @include tablet {
        font-size: 19px;
      }

      @include desktop {
        font-size: 20px;
      }

      // &:first-child {
      //   font-weight: bold;
      //   font-size: 18px;
      //   color: $color-3F4E5D;
      // }
    }
  }

  .btn {
    margin-top: 40px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.faq {
  margin-top: 80px;

  &__items {
    // box-shadow: $shadow-3;
    border-radius: 8px;
    border: 1px solid $color-A6B3BF;
    background: $color-FFFFFF;
  }

  &__item {
    border-bottom: 1px solid $color-A6B3BF;
    overflow: hidden;

    &:first-child {
      // border-top: 1px solid transparent;
      border-radius: 8px 8px 0px 0px;
    }

    &:last-child {
      border-radius: 0px 0px 8px 8px;
      border-bottom: 0;
    }

    &.selected {
      .faq {
        &__question {
          background: $color-ECF4F8;
          border-bottom: 1px solid $color-A6B3BF;
          cursor: initial;

          &::after {
            transform: rotate(180deg);
          }
        }

        &__answer {
          max-height: 100vh;
          transition: none;
        }
      }
    }
  }

  &__question {
    position: relative;
    display: flex;
    align-items: center;
    height: 56px;
    padding: 16px 70px 16px 28px;
    font-weight: bold;
    transition: 0.4s $ease-in-out-sine;
    cursor: pointer;
    border-bottom: 1px solid transparent;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 24px;
      margin: auto;
      background: url('@this-assets/imgs/down-arrow.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      width: 22px;
      height: 12px;
      transition: 0.4s $ease-in-out-sine;
    }
  }

  &__answer {
    max-height: 0;
    height: auto;
    overflow: hidden;
  }

  &__answer-content {
    padding: 9px 28px 14px 28px;
    max-width: 1211px;
  }
}

.news {
  padding-top: 39px;
  padding-bottom: 80px;

  &__item {
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $color-CEDDE6;

    &:first-child {
      padding-top: 0;
    }

    > .row {
      margin-left: -4px;
      margin-right: -4px;

      > .col {
        padding-left: 4px;
        padding-right: 4px;

        @include tablet_md {
          width: 100% !important;
        }

        &:first-child {
          width: 19.09%;

          @include tablet {
            width: 35%;
          }

          @include desktop {
            width: 30%;
          }
        }

        &:nth-child(2) {
          width: 80.91%;

          @include tablet {
            width: 65%;
          }

          @include desktop {
            width: 70%;
          }
        }
      }
    }
  }

  &__img {
    display: block;
    border-radius: 12px;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
    }
  }

  &__content {
    font-size: 16px;

    p {
      line-height: 125%;
    }
  }

  &__item-title {
    display: block;
    font-weight: bold;
    color: $color-3867B1;
    margin-bottom: 10px;
    line-height: 131%;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.news-page {
  position: relative;
  margin-top: 24px;
  padding-bottom: 80px;

  @include mobile {
    margin-top: 80px;
    padding-bottom: 60px;
  }

  @include tablet {
    padding-bottom: 70px;
  }

  // &::before {
  //   content: "";
  //   background-repeat: no-repeat;
  //   background-position: top right;
  //   background-size: cover;
  //   background-image: url(/img/news-bg.svg);
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   height: 287px;
  //   z-index: 1;
  // }

  > .container {
    position: relative;
    max-width: 1240px;
    z-index: 2;

    > .row {
      margin-left: -8px;
      margin-right: -8px;

      > .col {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }

  &__head {
    display: flex;
    flex-wrap: wrap;

    @include tablet_sm {
      flex-direction: column-reverse;
    }

    .title {
      color: $color-3867B1;
      text-transform: initial;
      text-align: left;

      &:first {
        padding-bottom: 0;
        padding-left: 0;
      }

      @include tablet_sm {
        width: 100%;
        padding-left: 0;
        padding-bottom: 16px;
      }

      @include tablet_md {
        width: 60%;
      }
    }
  }

  &__img {
    width: 34.376%;

    @include tablet_sm {
      width: 100%;
    }

    @include tablet_md {
      width: 40%;
    }

    img {
      display: block;
      width: 100%;
      border-radius: 12px;
    }
  }

  &__content {
    margin-top: 16px;
    text-align: justify;
    line-height: 125%;

    .title {
      text-align: left;
    }

    p {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        position: relative;
        display: block;
        padding-left: 14px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 2px;
          background: $color-3867B1;
        }
      }
    }

    img {
      display: block;
      width: 100%;
    }

    a {
      color: $color-3867B1;
      text-decoration: underline;
    }

    ul {
      padding-left: 14px;

      li {
        position: relative;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          content: "•";
          position: absolute;
          top: 0;
          right: 100%;
          margin-right: 6px;
        }
      }
    }
  }
}

.top-news {
  background: $color-ECF4F8;
  // box-shadow: $shadow-2;
  border-radius: 24px;
  padding: 16px 16px 56px 16px;

  > .title {
    margin-bottom: 28px;
    color: $color-3867B1;
    text-transform: initial;
    text-align: left;
  }

  &__items {
    > .row {
      margin: -12px -4px;

      > .col {
        padding: 12px 4px;

        @include tablet_sm {
          width: 100%;
        }

        @include tablet {
          width: 33.333333%;
        }
      }
    }
  }

  &__item {
    display: block;
  }

  &__img {
    // max-height: 136px;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      display: block;
      width: 100%;
      border-radius: 12px;
    }
  }

  &__title {
    color: $color-3867B1;
    font-weight: bold;
    font-size: 14px;
    text-align: center;

    @include mobile {
      font-size: 12px;
    }

    @include tablet {
      font-size: 13px;
    }
  }
}

.object-card {
  padding-top: 24px;
  padding-bottom: 90px;

  &__item {
    padding: 16px;
    border-radius: 4px;
    background: $color-FFFFFF;
    box-shadow: $shadow-7;

    > .row {
      > .col {
        &:first-child {
          width: 45%;
        }

        &:nth-child(2) {
          width: 55%;
        }
      }
    }
  }

  &__content {
    .title {
      font-weight: bold;
      text-align: left;
      line-height: 125%;
    }
  }

  &__content-head {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    > .title {
      color: $color-000000;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    p {
      font-weight: 600;
      color: $color-7E96AE;
    }
  }

  &__content-price {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    > .title {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    p {
      font-weight: 600;
      font-size: 21px;
      color: $color-7E96AE;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 136%;
  }

  .btn-block {
    > .row {
      margin: -8px;

      > .col {
        padding: 8px;
      }
    }
  }
}

@import './modules/footer.pure.scss';
$ENV_CDN_URL: "undefined";
@import '../../../front-land-invest-core/basic-styles/colors-const.pure';

.backgroundImg {
  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
  z-index: -1;
  img {
    width: 100%;
    height: 100%;
    object-position: top center;
  }
}

.titleContainer {
  display: grid;
  grid-template-columns: 192px 1fr;
  grid-column-gap: 24px;
}

.logoBlock {
  width: 192px;
  height: 192px;
  background-color: $white;
  border-radius: 32px;
}

.brightness {
  position: absolute;
  top: 120px;
  left: 300px;
  height: 192px;
  width: 100%;
  background: $white;
  filter: blur(80px);
}

.posRelative {
  position: relative;
}

.content {
  background-color: $white;
}
.title {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 40px;
  line-height: 160%;
  color: $color-3F4E5D;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 37px;

  @include mobile {
    font-size: 28px;
    margin-bottom: 20px;
  }

  @include tablet {
    font-size: 32px;
    margin-bottom: 30px;
  }

  @include desktop {
    font-size: 36px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &_small-md {
    font-size: 21px;
    margin-bottom: 28px;

    @include mobile {
      font-size: 17px;
    }

    @include tablet {
      font-size: 19px;
    }

    @include desktop {
      font-size: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &_md-md {
    font-size: 16px;

    @include mobile {
      font-size: 20px;
    }

    @include tablet {
      font-size: 22px;
    }

    @include desktop {
      font-size: 16px;
    }
  }

  &_md {
    font-size: 32px;
    font-weight: 600;
    text-transform: initial;

    @include mobile {
      font-size: 26px;
    }

    @include tablet {
      font-size: 28px;
    }

    @include desktop {
      font-size: 30px;
    }
  }
}

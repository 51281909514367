// Миксин для шрифтов
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1)+$replace+str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf) {
  $src: null;
  $extmods: (eot: "?",
      svg: "#"+ str-replace($name, " ", "_"));
  $formats: (otf: "opentype",
      ttf: "truetype"
    );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

// Миксин Треугольник

// Пример
//     @include triangle(left, 30px, 20px, red);
//     @include triangle(bottomright, 30px, 20px, red);

@mixin triangle($direction, $sizeH, $sizeV, $color) {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  -moz-transform: scale(.9999);

  @if $direction==top {
    border-width: 0 $sizeV $sizeH $sizeV;
    border-color: transparent transparent $color transparent;
  }

  @if $direction==bottom {
    border-width: $sizeV $sizeH 0 $sizeV;
    border-color: $color transparent transparent transparent;
  }

  @if $direction==left {
    border-width: $sizeV $sizeH $sizeV 0;
    border-color: transparent $color transparent transparent;
  }

  @if $direction==right {
    border-width: $sizeV 0 $sizeV $sizeH;
    border-color: transparent transparent transparent $color;
  }

  @if $direction==topright {
    border-width: 0 $sizeH $sizeV 0;
    border-color: transparent $color transparent transparent;
  }

  @if $direction==bottomright {
    border-width: 0 0 $sizeH $sizeV;
    border-color: transparent transparent $color transparent;
  }

  @if $direction==bottomleft {
    border-width: $sizeH 0 0 $sizeV;
    border-color: transparent transparent transparent $color;
  }

  @if $direction==topleft {
    border-width: $sizeH $sizeV 0 0;
    border-color: $color transparent transparent transparent;
  }
}
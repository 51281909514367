// Переменные с таймингом для transition, animation
$ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
$ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
$ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);

$color-FFFFFF: #ffffff;
$color-CEDDE6: #cedde6;
$color-ECF4F8: #ecf4f8;
$color-EEF2F4: #eef2f4;
$color-7E96AE: #7e96ae;
$color-A6B3BF: #a6b3bf;
$color-3F4E5D: #3f4e5d;
$color-303A4B: #303a4b;
$color-000000: #000000;
$color-3867B1: #3867b1;
$color-036FEE: #036fee;
$color-529ED0: #529ed0;
$color-7E96AE: #7e96ae;
$color-328C45: #328c45;
$color-81787b: #81787b;
$color-FA034D: #fa034d;
$color-665CAC: #665cac;

$shadow-1: 2px 2px 5px rgba(80, 130, 179, 0.17),
  0px 0px 4px rgba(21, 73, 124, 0.15);
$shadow-2: 4px 4px 24px rgba(90, 152, 176, 0.25),
  1px 1px 2px rgba(56, 101, 147, 0.15);
$shadow-3: 1px 1px 10px rgba(95, 152, 173, 0.3),
  1px 1px 2px rgba(56, 101, 147, 0.2);
$shadow-4: 2px 2px 7px rgba(57, 87, 117, 0.35);
$shadow-5: 2px 2px 8px rgba(95, 152, 173, 0.4),
  1px 1px 2px rgba(56, 101, 147, 0.2);
$shadow-6: 3px 5px 6px #6c9;
$shadow-7: 2px 2px 12px rgba(56, 101, 147, 0.15),
  -1px -1px 4px rgba(56, 101, 147, 0.2), 1px 1px 2px rgba(56, 101, 147, 0.2);

$gradient-1: linear-gradient(92.95deg, #529ED0 0%, #3867B1 100.29%);
$gradient-2: linear-gradient(90deg, #3867b1 0%, #529ed0 100%);
$gradient-3: linear-gradient(90deg, #7e96ae 0%, #cedde5 100%);

$ENV_CDN_URL: "undefined";
@import '../../../front-land-invest-core/basic-styles/colors-const.pure';
@import '../../scss/utils/media_mixins.pure';

.blockWithBorder {
  border-right: 1px solid $white;
}

@include tablet {
  .blockWithBorder {
    border-right: none;
  }
}
$ENV_CDN_URL: "undefined";
.contentDefault {
  margin-top: 5px;
  line-height: 125%;
  overflow-x: hidden;
  p {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
$ENV_CDN_URL: "undefined";
@import '@this-core/basic-styles/colors-const.pure';
@import '../../../scss/utils/vars.pure.scss';

.priceMarker {
  position: relative;
  left: -40px;
  top: -15px;
  background-color: $white;
  padding-top: 8px;
  padding-bottom: 7px;
  padding-left: 12px;
  padding-right: 12px;
  width: min-content;
  border-radius: 24px;
  box-shadow: $shadow-5;
  &:hover { 
    background: $dark-blue;
    p {
      color: $white;
    }
  }
}
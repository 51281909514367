@import '../utils/media_mixins.pure.scss';

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: fade-out($color-FFFFFF, 0.6);
  z-index: 1050;
  transition: 0.4s $ease-in-out-sine;

  > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    @include tablet {
      display: none;
    }
    .btn {
      width: 104px;
    }
  }
  &__burger {
    display: none;
    @include tablet {
      display: flex;
    }
  }

  &_dark {
    background: $color-3867B1;
  }
  &_scroll {
    background: $color-FFFFFF;
    box-shadow: $shadow-2;
  }
}

.main-nav {
  margin-right: 24px;
  &:last-child {
    margin-right: 0;
  }
  ul {
    display: flex;
    align-items: center;
    li {
      font-size: 16px;
      margin-right: 24px;
      white-space: nowrap;
      &:last-child {
        margin-right: 0;
      }
      a {
        color: $color-3F4E5D;
        cursor: pointer;
        &.active {
          font-weight: bold;
        }
      }
    }
  }
}

.mob-menu {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: fade-out($color-3F4E5D, 0.25);
  opacity: 0;
  visibility: hidden;
  z-index: 9997;
  transition: 0.3s $ease-in-out-sine 0.2s;
  &.active {
    opacity: 1;
    visibility: visible;
    transition: 0.3s $ease-in-out-sine;
    .mob-menu {
      &__content {
        transform: translateX(0);
        transition: 0.3s $ease-in-out-sine 0.2s;
      }
    }
  }
  &__content {
    background: $color-FFFFFF;
    width: 80.535%;
    height: 100%;
    margin-left: auto;
    transform: translateX(100%);
    padding-left: 7px;
    padding-right: 7px;
    transition: 0.3s $ease-in-out-sine;
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    padding-left: 9px;
    padding-right: 9px;
    border-bottom: 1px solid $color-CEDDE6;
  }

  .main-nav {
    ul {
      flex-direction: column;
      align-items: flex-start;

      li {
        height: 64px;
        padding: 9px;
        border-bottom: 1px solid $color-CEDDE6;
        border-left: 0;
        border-right: 0;
        width: 100%;
        margin-right: 0;

        a {
          display: flex;
          align-items: center;
          height: 100%;
          font-weight: 600;
          color: $color-303A4B;
          cursor: pointer;
        }
      }
    }
  }
}

$ENV_CDN_URL: "undefined";
.contentDefault {
  margin-top: 16px;
  text-align: justify;
  line-height: 125%;
  p {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

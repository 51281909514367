// Link to this file
// @import '@this-core/basic-styles/colors-const.pure.scss';

$white: #ffffff !default;
$black: #000000 !default;
$clear: #fcc4c400 !default;

$purple: #665CAC !default;

$orange: #FF7C1D !default;
$light-orange: #ffaf75 !default;
$dark-gray: #3F4E5D !default;
$gray: #7E96AE !default;
$light-gray: #CEDDE6 !default;
$blue: #529ED0 !default;

$light-blue: #ECF4F8 !default;
$dark-blue: #3867B1 !default;
@import './colors-const.pure.scss';

a {
  color: $black;
  &:disabled {
    pointer-events: none;
    cursor: default;
  }
}

button {
  padding: 0;
  margin: 0;
  border: none;
  font-family: "OpenSans";
  background: none;
  color: $black;
  &:focus {
    outline: none;
  }
}
$ENV_CDN_URL: "undefined";
@import '@this-core/basic-styles/colors-const.pure.scss';
@import '../../../scss/utils/vars.pure.scss';

.container {
  width: 496px;
  background-color: $white;
  border-radius: 8px;
  box-shadow: $shadow-2;
}

.img {
  width: 186px;
  height: 186px;
  border-top-left-radius: 8px;
  svg {
    border-top-left-radius: 8px;
  }
}

.rightBlock {
  max-width: 310px;
}

.buttomText {
  color: $dark-blue;
  font-weight: 600;
}

.buttomBlock {
  height: 40px;
  border-top: 1px solid $light-gray;
  background-color: $light-blue;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
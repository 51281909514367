$ENV_CDN_URL: "undefined";
@import '@this-core/basic-styles/colors-const.pure.scss';

.inputContainer {
  position: relative;
  z-index: 5;
  top: 15px;
  left: 60px;

  background-color: $white;
  border-radius: 10px;
  width: 350px;
  input {
    height: 40px;
    font-size: 16px;
    width: 300px;
  }
}

.serchIcon {
  position: relative;
  top: 1px;
}

.dropDownList {
  position: absolute;
  z-index: 1;
  top: 30px;
  width: 350px;
  max-height: 400px;
  overflow-y: auto;
  padding: 5px 10px;
  background: #fff;
  border-radius: 0 0 10px 10px;
  cursor: pointer;
}
$ENV_CDN_URL: "undefined";
@import '@this-core/basic-styles/colors-const.pure.scss';

.text {
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  color: $dark-blue;
}

.overflowHide {
  overflow-x: hidden;
}